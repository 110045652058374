<template>
  <div class="accountset enterpriselist">
    <userheader menucurrent="2" />
    <div class="main-div">
      <div class="account-content wrap">
        <userleft currindex="5" />
        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">创建简历<em></em></h3>
              <div class="forms">
                <!-- form表单 -->
                <el-form
                  :model="form"
                  :rules="rules"
                  ref="forms"
                  label-width="100px"
                >
                  <!-- 公共样式 -->
                  <div class="commit">
                    <div class="commit_icon">
                      <span>
                        <i class="el-icon-edit"></i>
                      </span>
                      <span>求职信息</span>
                    </div>
                    <div class="separator"></div>
                  </div>
                  <div class="commitBox">
                    <!-- 职位类别 -->
                    <el-row :gutter="20" style="marginleft: 230px">
                      <el-col :span="10">
                        <el-form-item
                          class="formL"
                          label="职位类别"
                          prop="expectJobs"
                        >
                          <el-cascader
                            v-model="form.expectJobs"
                            :options="expectJobsOpt"
                            style="width: 200px"
                            @change="getCascader"
                          ></el-cascader>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 工作地点 -->
                    <el-row :gutter="5" style="marginleft: 240px">
                      <el-col :span="18">
                        <el-form-item
                          class="forma"
                          label="工作地点"
                          prop="workAddress"
                        >
                          <el-cascader
                            v-model="form.workAddress"
                            :options="cityList"
                            style="width: 200px"
                            @change="getWorkAddress"
                          ></el-cascader>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 期望薪资 -->
                    <el-row :gutter="20" style="marginleft: 230px">
                      <el-col :span="10">
                        <el-form-item class="formL" label="期望薪资">
                          <el-select
                            v-model="form.expectSalaryCode"
                            placeholder="请选择"
                            style="width: 200px"
                            @change="getexpectSalary"
                          >
                            <el-option
                              v-for="(item, index) in expectSalaryList"
                              :key="index"
                              :label="item.name"
                              :value="item.code"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- 公共样式 -->
                  <div class="commit">
                    <div class="commit_icon">
                      <span>
                        <i class="el-icon-edit"></i>
                      </span>
                      <span>基本信息</span>
                    </div>
                    <div class="separator"></div>
                  </div>
                  <div class="commitBox">
                    <!-- 头像 -->
                    <el-row :gutter="20" style="marginleft: 230px">
                      <el-col :span="10">
                        <el-form-item
                          class="formL"
                          label="头像"
                          prop="avatarId"
                        >
                          <el-upload
                            :action="uploadUrl"
                            list-type="picture-card"
                            name="file"
                            :headers="myHeaders"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :on-progress="beforeAvatarUpload"
                          >
                            <img
                              v-if="imageUrl"
                              :src="imageUrl"
                              class="avatar"
                            />
                            <img
                              v-else
                              src="../../assets/img/timgs.jpg"
                              class="avatar"
                            />
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 姓名 -->
                    <el-row :gutter="20" style="marginleft: 230px">
                      <el-col :span="10">
                        <el-form-item
                          class="formL"
                          label="姓名"
                          prop="username"
                          style="width: 300px"
                        >
                        <span>{{form.username}}</span>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 性别 -->
                    <el-row :gutter="20" style="marginleft: 230px">
                      <el-col :span="10">
                        <el-form-item class="formL" label="性别" prop="sex">
                           <span>{{form.sex}}</span>
                          <el-select v-if="false"
                            v-model="form.sexCode"
                            placeholder="请选择"
                            style="width: 200px"
                            @change="getSex"
                          >
                            <el-option
                              v-for="item in sexList"
                              :key="item.value"
                              :label="item.name"
                              :value="item.code"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 出生年份 -->
                    <el-row :gutter="0" class="time" style="marginleft: 230px">
                      <el-col :span="15">
                        
                        <el-form-item 
                          label="年龄"
                          required
                          style="marginleft: 25px"
                          prop="age"
                        ><span>{{form.age}}</span>
                          <!-- <el-input v-model="form.age" style="width: 200px" /> -->
                        </el-form-item>
                      </el-col>
                    </el-row>
     <el-row :gutter="0" class="time" style="marginleft: 230px">
                      <el-col :span="15">
                        
                        <el-form-item 
                          label="职称名称"
                          required
                          style="marginleft: 25px"
                          prop="age"
                        >
                           <span class="authentication" v-for="(item,index) in professioncertlistdata"
                            v-bind:key="item.id">
                            <span v-if="index!=professioncertlistdata.length-1">
                              {{item.certType}}、
                            </span>
                            <span v-else>
                              {{item.certType}}
                            </span>
                          </span>
                          <!-- <el-input v-model="form.age" style="width: 200px" /> -->
                        </el-form-item>
                      </el-col>
                    </el-row>
    

                    <!-- 工作年限 -->
                    <el-row :gutter="0" class="time" style="marginleft: 230px">
                      <el-col :span="15">
                        <el-form-item
                          label="工作年限"
                          required
                          style="marginleft: 25px"
                          prop="workExp"
                        >
                          <el-col :span="10">
                            <el-select
                              v-model="form.workExp"
                              placeholder="请选择"
                              style="width: 200px"
                              @change="getworkExp"
                            >
                              <el-option
                                v-for="(item, index) in workExpOptions"
                                :key="index"
                                :label="item.name"
                                :value="item.code"
                              ></el-option>
                            </el-select>
                          </el-col>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 最高学历 -->
                    <el-row :gutter="20" style="marginleft: 230px">
                      <el-col :span="10">
                        <el-form-item
                          class="formL"
                          label="最高学历"
                          prop="education"
                        >
                          <el-select
                            v-model="form.education"
                            placeholder="请选择"
                            style="width: 200px"
                            @change="getEducation"
                          >
                            <el-option
                              v-for="item in educations"
                              :key="item.value"
                              :label="item.name"
                              :value="item.code"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 手机号码 -->
                    <el-row :gutter="20" style="marginleft: 230px">
                      <el-col :span="8">
                        <el-form-item
                          class="formL"
                          label="手机号码"
                          prop="phone"
                        >
                          <el-input
                            v-model="form.phone"
                            style="width: 200px"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="2" class="btn">
                        <el-button type="primary" id="btns" @click="set()"
                          >获取验证码</el-button
                        >
                        <el-button type="primary" id="btnz">{{
                          time
                        }}</el-button>
                      </el-col>
                    </el-row>
                    <!-- 验证码 -->
                    <el-row :gutter="20" style="marginleft: 230px">
                      <el-col :span="8">
                        <el-form-item
                          class="formL"
                          label="验证码"
                          prop="phoneCode"
                        >
                          <el-input
                            v-model="form.phoneCode"
                            style="width: 100px"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 籍贯 -->
                    <el-row :gutter="10" style="marginleft: 234px">
                      <el-col :span="18">
                        <el-form-item
                          class="forma"
                          label="籍贯"
                          prop="nativePlace"
                        >
                        <span>{{form.nativePlace.county}}</span>
                          <!-- <el-cascader
                            v-model="form.nativePlace"
                            :options="cityList"
                            style="width: 200px"
                            @change="getNativePlace"
                          ></el-cascader> -->
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 现居住地 -->
                    <el-row :gutter="10" style="marginleft: 234px">
                      <el-col :span="18">
                        <el-form-item
                          class="forma"
                          label="现居住地"
                          prop="address"
                        >
                         <el-input
                            v-model="form.address.county"
                            style="width: 300px"
                          ></el-input>
                          <!-- <el-cascader
                            v-model="form.address"
                            :options="cityList"
                            style="width: 200px"
                            @change="getAddress"
                          ></el-cascader> -->
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 自我介绍 -->
                    <el-row :gutter="20" style="marginleft: 230px">
                      <el-col :span="15">
                        <el-form-item
                          class="formL"
                          label="自我介绍"
                          prop="selfAssessment"
                        >
                          <el-input
                            type="textarea"
                            :rows="5"
                            v-model="form.selfAssessment"
                            size="medium"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- 公共样式 -->
                  <div class="commit">
                    <div class="commit_icon">
                      <span>
                        <i class="el-icon-edit"></i>
                      </span>
                      <span>附件简历</span>
                    </div>
                    <div class="separator"></div>
                  </div>
                  <div class="commitBox">
                    <!-- 上传文件 -->
                    <el-row :gutter="20" style="marginleft: 230px">
                      <el-form-item class="formL" label prop="resume">
                        <el-col :span="11">
                          <el-upload
                            class="upload-demo"
                            drag
                            :action="uploadResume"
                            :headers="myHeaders"
                            :data="uploadResumeData"
                            name="file"
                            :limit="1"
                            :on-success="resumeSuccess"
                            :on-error="resumeError"
                          >
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                              将文件拖到此处，或
                              <em>点击上传</em>
                            </div>
                          </el-upload>
                        </el-col>
                      </el-form-item>
                    </el-row>
                  </div>
                  <!-- 公共样式 -->
                  <div class="commit">
                    <div class="commit_icon">
                      <span>
                        <i class="el-icon-edit"></i>
                      </span>
                      <span>隐私设置</span>
                    </div>
                    <div class="separator"></div>
                  </div>
                  <div class="commitBox1">
                    <!-- 选择权限 -->
                    <el-row :gutter="20" style="marginleft: 230px">
                      <el-col :span="10">
                        <el-form-item
                          class="formL"
                          label="谁能看到这份简历"
                          prop="privacySetting"
                          label-width="200px"
                        >
                          <el-radio
                            v-model="form.privacySetting"
                            label="0"
                            class="choice"
                          >
                            <span class="choice_title">所有招聘方</span>
                            <span class="choice_class"
                              >展示简历给通过营业执照或法人等认证的招聘方</span
                            >
                          </el-radio>
                          <el-radio
                            v-model="form.privacySetting"
                            label="1"
                            class="choice"
                          >
                            <span class="choice_title">仅我投递的招聘方</span>
                            <span class="choice_class"
                              >不会在人才库显示您的简历，不会被非投递的招聘方主动邀约</span
                            >
                          </el-radio>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- btn -->
                    <el-row :gutter="20" style="marginleft: 230px">
                      <el-col :span="10">
                        <el-button
                          type="primary"
                          class="button"
                          @click="postResume"
                          >保存文档</el-button
                        >
                      </el-col>
                    </el-row>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import userleft from '@/components/userleft.vue';
import { postResumes, ExpectJobs, ExpectJobs1, phone } from '@/api/resume';
import { usergetcurruserinfo } from '@/api/user';
import { cityjson } from '@/utils/cityjson.js';
import { professioncertlist } from '@/api/user';

export default {
  name: 'newResume',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data: function () {
    return {
      professioncertlistdata: [],
      // entityId: '5418',
      form: {
        category: '',
        // 姓名
        username: '',
        // 性别
        sex: '',
        // 性别编码
        sexCode: '',
        // 年龄
        age: '',
        // date1: '',
        desc: '',
        // 电话
        phone: '',
        // 自我介绍
        selfAssessment: '',
        // 职业类别
        expectJobs: 0,
        // 隐藏设置
        privacySetting: '0',
        // 工作地点
        workAddress: {
          // 省
          provinceCode: '',
          provinceName: '',
          // 市
          cityCode: '',
          citysName: '',
          // 镇
          townCode: '',
          townName: '',
        },
        // 籍贯
        nativePlace: {
          // 省
          provinces: '',
          provinceName: '',
          provinceCode: '',
          // 市
          citys: '',
          citysName: '',
          cityCode: '',
          // 镇
          towns: '',
          townName: '',
          townCode: '',
        },
        // 居住地
        address: {
          // 省
          provinces: '',
          provinceName: '',
          provinceCode: '',
          // 市
          citys: '',
          citysName: '',
          cityCode: '',
          // 镇
          towns: '',
          townName: '',
          townCode: '',
        },
        // 工作年限
        workExp: '',
        // 期望薪资
        expectSalary: '',
        expectSalaryCode: '',
        // 验证码
        phoneCode: '',
        // 学历
        education: '',
        educationCode: '',
        //城市编码
        cityCode: '',
        //期望城市
        workCity: '',
      },
      rules: {
        username: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        phone: [
          { required: true, message: '不能为空', trigger: 'blur' },
          { pattern: /^\d{11}$/, message: '格式错误，仅支持11位数字' },
        ],
        expectJobs: [{ required: true, message: '不能为空', trigger: 'blur' }],
        age: [{ required: true, message: '请输入年龄', trigger: 'blur' }],
      },
      expectJobsOpt: [],
      // 学历
      educations: [],
      // 图片上传
      imageUrl: '',
      // 验证码时间
      time: 60,
      times: '',
      // 公用的地点名称
      place: '',
      place1: '',
      place2: '',
      // 用户id
      // uid: 230,
      // 工作年限
      workExpOptions: [
        {
          code: 1,
          name: '一年以上',
        },
        {
          code: 2,
          name: '两年以上',
        },
        {
          code: 3,
          name: '三年以上',
        },
        {
          code: 4,
          name: '四年以上',
        },
        {
          code: 5,
          name: '五年以上',
        },
        {
          code: 6,
          name: '六年以上',
        },
        {
          code: 7,
          name: '七年以上',
        },
        {
          code: 8,
          name: '八年以上',
        },
        {
          code: 9,
          name: '九年以上',
        },
        {
          code: 10,
          name: '十年以上',
        },
      ],
      // 期望薪资的数据
      expectSalaryList: [],
      // 性别
      sexList: [],
      // 添加求职的参数
      bean: {
        expectJobs: 0,
        workArea: {
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          longitude: '',
          latitude: '',
        },
        expectSalary: '',
        expectSalaryCode: '',
        avatarId: '',
        username: '',
        sex: '',
        sexCode: '',
        age: 0,
        workExp: '',
        education: '',
        educationCode: '',
        phone: '',
        phoneCode: '',
        nativeArea: {
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          longitude: '',
          latitude: '',
        },
        residenceArea: {
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          longitude: '',
          latitude: '',
        },
        selfAssessment: '',
        resume: {
          fileToken: '',
          url: '',
          name: '',
          length: 0,
          sign: '',
        },
        privacySetting: 0,
        id: 0,
      },

      // 头像参数
      avatarId: '',
      // // 上传简历的参数
      resumes: {
        fileToken: '',
        url: '',
        name: '',
        length: 0,
        sign: '',
      },
      // 上传的请求头
      myHeaders: { Authorization: 'Bearer ' + token },
      //UploadeUrl地址
      uploadUrl: this.$uplodeUrl + '/utils/uploadfile',
      // 工作地点拼接名
      workAddressP: '',
      // 籍贯拼接名
      nativePlaceP: '',
      // 居住地拼接名
      addressP: '',
      // 简历地址
      uploadResume: this.$uplodeUrl + '/utils/uploadfile',
      uploadResumeData: {
        fileType: 'candidate',
        fileTypeCode: '简历',
      },
      // 进度条数据
      // percentage: 30,
      // 城市列表数据
      cityList: cityjson,
      // 期望城市编码
      workExpCode: '',
    };
  },
  mounted() {
    document.getElementById('btnz').style.display = 'none';
    this.getExpectJobs();
    this.getCandidatefilter();
    this.onprofessioncertlist();
    var that = this;
    this.user_account =getCookieByName('user_account'); 
    this.userNickName = this.user_account;
    let accessToken =getCookieByName('access_token'); 
    if (accessToken) {
      this.userAccount =getCookieByName('user_account');
      usergetcurruserinfo().then((res) => {
        if (res.data.code == 1) {
          this.userinfo = res.data.result;
          if (that.userinfo.userAvatar) {
            that.userAvatar = that.userinfo.userAvatar;
          }
          if (that.userinfo.userNickName) {
            that.userNickName = this.userinfo.userNickName;
          }
          this.form.username=this.userinfo.iDcardName;
          this.form.sex=this.userinfo.iDcardSex;
          this.form.sexCode=this.form.sex=='女'?'f':'m';
          this.form.phone=this.userinfo.userMobile;
          this.form.age=this.userinfo.userAge;
          this.form.nativePlace.county=this.userinfo.iDcardAddress;
        }
      });
    }

  },
  methods: {
    onprofessioncertlist() {
      var that = this;
      var data = { verifyStatus: 1 };
      professioncertlist(data).then((res) => {
        if (res.data.code === 1) {
          that.professioncertlistdata = res.data.result;
        }
      });
    },
    //退出
    getOut() {
      window.history.go(-1);
    },
    handleClick(tab, event) {},
    // 上传头像前的钩子函数
    beforeAvatarUpload(file) {},
    // 上传头像成功的钩子函数
    handleAvatarSuccess(response, file) {
      this.imageUrl = file.url;
      this.form.avatarId = response.result.fileToken;
    },
    // 验证码
    async set() {
      const reg = /^1[0-9]\d{9}$/;
      if (!reg.test(this.form.phone)) {
        document.getElementById('btnz').style.display = 'none';
      } else {
        document.getElementById('btns').style.display = 'none';
        // document.getElementById("btns").disabled=ture;
        document.getElementById('btnz').style.display = 'block';
        this.times = setInterval(this.depTime, 1000);
        const res = await phone({ userMobile: this.form.phone });
        if (res.data.code == 1) {
          this.$message.success('发送成功');
        } else {
          this.$message.error('发送失败');
        }
      }
    },
    depTime() {
      if (this.time > 0) {
        this.time--;
        document.getElementById('btnz').style.disabled = true;
      } else {
        clearInterval(this.times);
        document.getElementById('btns').style.display = 'block';
        document.getElementById('btnz').style.display = 'none';
        this.time = 60;
      }
    },
    // 工作地点事件
    async getWorkAddress(e) {
      this.getLoop(e[0], this.cityList);
      this.form.workAddress.provinceCode = e[0];
      this.form.workAddress.provinceName = this.place;
      // 市
      let code = e[0];
      const res = await ExpectJobs1(code);
      let countys = res.data.result.countys;
      this.getLoop1(e[1], countys);
      this.form.workAddress.cityCode = e[1];
      this.form.workAddress.citysName = this.place1;
      // 县
      let code1 = e[1];
      const res1 = await ExpectJobs1(code1);
      let countys1 = res1.data.result.countys;
      this.getLoop2(e[2], countys1);
      this.form.workAddress.townCode = e[2];
      this.form.workAddress.townName = this.place2;
    },
    // 籍贯事件
    async getNativePlace(e) {
      this.getLoop(e[0], this.cityList);
      this.form.nativePlace.provinceCode = e[0];
      this.form.nativePlace.provinceName = this.place;
      // 市
      let code = e[0];
      const res = await ExpectJobs1(code);
      let countys = res.data.result.countys;
      this.getLoop1(e[1], countys);
      this.form.nativePlace.cityCode = e[1];
      this.form.nativePlace.citysName = this.place1;
      // 县
      let code1 = e[1];
      const res1 = await ExpectJobs1(code1);
      let countys1 = res1.data.result.countys;
      this.getLoop2(e[2], countys1);
      this.form.nativePlace.townCode = e[2];
      this.form.nativePlace.townName = this.place2;
    },
    // 居住地事件
    async getAddress(e) {
      this.getLoop(e[0], this.cityList);
      this.form.address.provinceCode = e[0];
      this.form.address.provinceName = this.place;
      // 市
      let code = e[0];
      const res = await ExpectJobs1(code);
      let countys = res.data.result.countys;
      this.getLoop1(e[1], countys);
      this.form.address.cityCode = e[1];
      this.form.address.citysName = this.place1;
      // 县
      let code1 = e[1];
      const res1 = await ExpectJobs1(code1);
      let countys1 = res1.data.result.countys;
      this.getLoop2(e[2], countys1);
      this.form.address.townCode = e[2];
      this.form.address.townName = this.place2;
    },
    // 循环地点名称事件
    getLoop(code, arr) {
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item.itemValue == code) {
            this.place = item.itemText;
          } else if (item.code == code) {
            this.place = item.name;
          } else if (item.value == code) {
            this.place = item.label;
          } else {
            return;
          }
        });
      }
    },
    getLoop1(code, arr) {
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item.itemValue == code) {
            this.place1 = item.itemText;
          } else if (item.code == code) {
            this.place1 = item.name;
          } else if (item.value == code) {
            this.place1 = item.label;
          } else {
            return;
          }
        });
      }
    },
    getLoop2(code, arr) {
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item.itemValue == code) {
            this.place2 = item.itemText;
          } else if (item.code == code) {
            this.place2 = item.name;
          } else if (item.value == code) {
            this.place2 = item.label;
          } else {
            return;
          }
        });
      }
    },
    // 头像上传时的钩子函数
    avatarIdPreview(file) {
      this.file = file;
    },
    // 期望薪资事件
    getexpectSalary(e) {
      this.getLoop(e, this.expectSalaryList);
      this.form.expectSalary = this.place;
      this.form.expectSalaryCode = e;
    },
    // 工作年限事件
    getworkExp(e) {
      this.getLoop(e, this.workExpOptions);
      this.form.workExp = this.place;
      this.workExpCode = e;
    },
    // 学历
    getEducation(e) {
      this.getLoop(e, this.educations);
      // console.log(this.place);
      // console.log(e);
      this.form.education = this.place;
      this.form.educationCode = e;
    },
    // 性别
    getSex(e) {
      this.getLoop(e, this.sexList);
      this.form.sex = this.place;
      this.form.sexCode = e;
      // console.log(this.place)
      // console.log(e)
    },
    // 职业类别
    async getExpectJobs() {
      const { data: res } = await ExpectJobs();
      let Jobs = res.result.job;
      let newarr = [];
      this.handleData1(Jobs, newarr);
      this.expectJobsOpt = newarr;
    },
    // 级联选择器循环事件
    handleData(data, newarr) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        //如果子级存在,并且有数据
        if (data[i].children && data[i].children.length > 0) {
          //label为级联选择器要展示的值,我这里使用的是name,value为选中的值,我这里使用的是id,根据个人使用场景,按需调试
          newarr.push({ value: data[i].id, label: data[i].code, children: [] });
          this.handleData(data[i].children, newarr[i].children);
        } else {
          //子级不存在时,不用push  children:[],否则级联选择器最后一页会出现空白,很难看
          newarr.push({ value: data[i].id, label: data[i].code });
        }
      }
      return newarr;
    },
    handleData1(data, newarr) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        //如果子级存在,并且有数据
        if (data[i].children && data[i].children.length > 0) {
          //label为级联选择器要展示的值,我这里使用的是name,value为选中的值,我这里使用的是id,根据个人使用场景,按需调试
          newarr.push({ value: data[i].id, label: data[i].name, children: [] });
          this.handleData(data[i].children, newarr[i].children);
        } else {
          //子级不存在时,不用push  children:[],否则级联选择器最后一页会出现空白,很难看
          newarr.push({ value: data[i].id, label: data[i].name });
        }
      }
      return newarr;
    },
    // 职业类别级联选择器事件
    getCascader(e) {
      for (let i = 0; i < e.length; i++) {
        this.form.expectJobs = e[e.length - 1];
      }
    },
    // 上传简历成功时的钩子
    resumeSuccess(response, file, fileList) {
      this.resumes.fileToken = response.result.fileToken;
      this.resumes.url = response.result.url;
      this.resumes.name = response.result.name;
      this.resumes.length = response.result.length;
      this.resumes.sign = response.result.sign;
    },
    // 上传简历失败的钩子
    resumeError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传简历失败');
    },
    // 求职过滤条件
    async getCandidatefilter() {
      const res = await ExpectJobs();
      if (res.data.code == 1) {
        let expectSalaryList = res.data.result.sals;
        this.expectSalaryList = expectSalaryList.slice(
          1,
          expectSalaryList.length - 1
        );
        // let educations = res.result.educations;
        let educations = res.data.result.edus;
        this.educations = educations.slice(1, educations.length - 1);
        this.sexList = res.data.result.sexs;
      } else {
        this.$message.error('过滤条件获取失败！');
      }
    },
    // 保存简历事件
    async postResume() {
      this.bean.expectSalary = this.form.expectSalary;
      this.bean.expectSalaryCode = this.form.expectSalaryCode;
      this.bean.expectJobs = this.form.expectJobs;
      this.bean.avatarId = this.form.avatarId;
      this.bean.username = this.form.username;
      this.bean.sex = this.form.sex;
      this.bean.sexCode = this.form.sexCode;
      this.bean.age = this.form.age;
      this.bean.education = this.form.education;
      this.bean.educationCode = this.form.educationCode;
      this.bean.phone = this.form.phone;
      this.bean.phoneCode = this.form.phoneCode;
      this.bean.selfAssessment = this.form.selfAssessment;
      this.bean.resume = this.resumes;
      this.bean.privacySetting = parseInt(this.form.privacySetting);
      this.bean.workExp = this.workExpCode;

      // 工作地点
      this.bean.workArea.province = this.form.workAddress.provinceName;
      this.bean.workArea.provinceCode = this.form.workAddress.provinceCode;
      this.bean.workArea.city = this.form.workAddress.citysName;
      this.bean.workArea.cityCode = this.form.workAddress.cityCode;
      this.bean.workArea.county = this.form.workAddress.townName;
      this.bean.workArea.countyCode = this.form.workAddress.townCode;
      // 籍贯
      this.bean.nativeArea.province = this.form.nativePlace.provinceName;
      this.bean.nativeArea.provinceCode = this.form.nativePlace.provinceCode;
      this.bean.nativeArea.city = this.form.nativePlace.citysName;
      this.bean.nativeArea.cityCode = this.form.nativePlace.cityCode;
      this.bean.nativeArea.county = this.form.nativePlace.county;
      this.bean.nativeArea.countyCode = this.form.nativePlace.townCode;
      // 居住地 address
      this.bean.residenceArea.province = this.form.address.provinceName;
      this.bean.residenceArea.provinceCode = this.form.address.provinceCode;
      this.bean.residenceArea.city = this.form.address.citysName;
      this.bean.residenceArea.cityCode = this.form.address.cityCode;
      this.bean.residenceArea.county = this.form.address.county;
      this.bean.residenceArea.countyCode = this.form.address.townCode;
      this.$refs.forms.validate(async (valid) => {
        if (!valid) {
          return this.$message.error('表单错误请重新添加表单');
        } else {
          let bean = this.bean;
          const res = await postResumes(bean);
          if (res.data.code == 1) {
            this.$message.success('提交成功');
            clearInterval(this.times);
            this.$router.push('/jobwanted');
          } else {
             this.$message.error(res.data.message);
          }
        }
      });
    },
  },
};
</script>
<style scoped>
/* 公共样式开始 */
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 880px;
  margin-right: 60px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}
.accountset .content-rb .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.accountset .content-rb .empty i {
  font-size: 40px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.accountset .content-rb .empty span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #ddd;
}
.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/* 公共样式结束 */
/* 表单样式 */
.commit {
  display: flex;
  flex-direction: row;
  color: #8c8c8c;
  /* margin: 30px 0px 0px -70px; */
  font-size: 18px;
  /* width: 1030px; */
  margin: 30px 0 0 20px;
}
.iocns {
  margin-left: 10px;
}
.formL {
  margin: 30px 0px 0 15px;
}
.forma {
  margin: 30px 0px 0 14px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 100%;
  height: 100%;
  display: block;
}
.time {
  margin-top: 20px;
  margin-left: 14px;
}
.btn {
  margin: 20px 0 0 50px;
}
.button {
  margin-left: 100px;
}
.choice_title {
  margin-right: 30px;
}
.choice_class {
  color: 999999;
}
.el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  /* width: 100%; */
  width: 500px;
  height: 200px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.allCheckRight {
  color: #f88a41;
}
.checkJobMsg {
  width: 50px;
  text-align: center;
  border: 1px solid #f88a41;
  margin-top: 10px;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #ff603b;
  background: #ff603b;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #ff603b;
}
.commitBox {
  margin-left: 100px;
  width: 100%;
}
.commitBox1 {
  margin-left: 40px;
  width: 100%;
}
.separator {
  width: 700px;
  border: 0;
  border-bottom: 3px dotted #a09e9e;
  height: 0;
  margin: 11px 0 0 5px;
}
.el-form-item{
  margin-bottom:inherit;
}
.formL,.forma{
  margin-top: 20px;
}
</style>